import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

import Carousel from 'components/ui/Carousel/Carousel';
import FeaturedProductCard from './FeaturedProductCard';
import { ProductPreview, ProductPreviewWithColorCollection } from 'domain/types';

const SLIDES_PER_SIZE = {
  small: {
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4
  },
  large: {
    sm: 1,
    md: 2,
    lg: 3,
    xl: 3
  }
};

interface FeaturedProductsProps {
  products: ProductPreviewWithColorCollection[] | ProductPreview[];
  size?: 'small' | 'large';
}

const FeaturedProducts: React.FC<FeaturedProductsProps> = ({
  products,
  size = 'large'
}): JSX.Element => {
  const classes = useStyles();
  const {
    breakpoints: { values: breakpoints }
  } = useTheme();

  return (
    <Carousel
      settings={{
        slidesToScroll: 2,
        slidesToShow: 1,
        arrows: true,
        infinite: false,
        responsive: [
          {
            breakpoint: breakpoints.sm,
            settings: {
              slidesToShow: SLIDES_PER_SIZE[size].sm
            }
          },
          {
            breakpoint: breakpoints.md,
            settings: {
              slidesToShow: SLIDES_PER_SIZE[size].md
            }
          },
          {
            breakpoint: breakpoints.lg,
            settings: {
              slidesToShow: SLIDES_PER_SIZE[size].lg
            }
          },
          {
            breakpoint: 10000,
            settings: {
              slidesToShow: SLIDES_PER_SIZE[size].xl
            }
          }
        ],
        className: classes.container
      }}
    >
      {products.map((product) => (
        <FeaturedProductCard product={product} key={product.sys.id} size={size} />
      ))}
    </Carousel>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    ['& .slick-list']: {
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2)
    },
    ['& .slick-slide']: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      boxSizing: 'border-box'
    }
  }
}));

export default FeaturedProducts;
